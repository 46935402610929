import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SIcon, SSelect } from "@avalara/skylab-react";
import { useId } from "@certcapture/react-components";
import { checkSelectComponentProps, errorTag, noErrorTag } from "../../../shared/Utils";

import {
    fetchAvaTaxCategoriesAsync,
    selectIsLoadingAvaProductCategories,
    selectAvaProductCategories,
} from "../../../app/productCategoriesSlice";

const SelectAvataxCategories = React.memo(props => {
    const [getCurrentId, getNextId] = useId();
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingAvaProductCategories);

    const avaCategories = useSelector(selectAvaProductCategories).map(element => {
        const selectOption = {
            label: element,
            value: element,
            selected: false,
        };
        if (props.value?.includes(element)) {
            selectOption.selected = true;
        }
        return selectOption;
    });

    checkSelectComponentProps("selectAvaProductCategories", props);

    useEffect(() => {
        if (props.avaCheck) {
            dispatch(fetchAvaTaxCategoriesAsync());
        }
    }, [dispatch, props.avaCheck]);

    const labelText = props.avaCheck ? "Avalara tax codes" : "Tax codes";

    const codeElement = (
        <div>
            {props.avaCheck ? (
                <SSelect
                    name="avataxProductCategorySelect"
                    inputId={getCurrentId()}
                    optionsList={avaCategories}
                    loading={isLoading}
                    onS-select={e => props.onAdd(e.detail.item)}
                    onS-deselect={e => props.onRemove(e.detail.item)}
                    className={props.codeError === "error" ? errorTag : noErrorTag}
                    noSearch
                    multiple
                />
            ) : (
                <div>
                    <input
                        ref={props.codeRef}
                        type="text"
                        defaultValue={props.value}
                        onBlur={e => props.onInput(e.target.value)}
                        className={props.codeError === "error" ? errorTag : noErrorTag}
                    />
                    <span className="helpr-txt">Use commas between multiple tax codes.</span>
                </div>
            )}
        </div>
    );

    return (
        <React.Fragment>
            <label className="required" htmlFor={getNextId()} id="lbl-category-code">
                {labelText}
            </label>
            {codeElement}
            {props.codeError === "error" ? (
                <div className="text-red-dark text-sm-strong text-align-middle">
                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                    <span className="margin-left-xs">Enter tax codes </span>
                </div>
            ) : null}
        </React.Fragment>
    );
});
export default SelectAvataxCategories;
